import request from '@/utils/request-service-admin.js'

const mtoUserProfile = {
    // ========================================
    getUserProfileListByPage: async (userStatus, totalRows, pageIndex) => {
        return await request({
            url: '/admintool/userProfile/get-user-profile-list',
            method: 'post',
            data: {
                userStatus: userStatus,
                totalRows: totalRows,
                pageIndex: pageIndex
            }
        })
    },

    // ========================================
	requestUpdateUserProfileStatus: async (userID, userStatus) => {
        return await request({
            url: '/admintool/userProfile/update-user-profile-status',
            method: 'post',
            data: {
                id: userID,
                userStatus: userStatus
            }
        })
	}
}

export default mtoUserProfile;